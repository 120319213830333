.pc-index .section3 .right .pro2 li a .desc:after, .mob-index .section3 .right .pro1:after, .mob-index .section3 .right .pro2 li a .desc:after {
  content: "";
  display: block;
  clear: both;
}

footer .footer-nav {
  display: block;
}

@media (max-width: 991px) {
  footer .footer-nav {
    display: none;
  }
}

body {
  background-image: url(./images/index_bg2.jpg);
  background-image: url(./images/index_bg3.jpg);
  background-position: center;
  background-size: cover;
  transition: all .6s;
  background-image: url(./images/index_bg1.jpg);
}

@media (max-width: 1024px) {
  body {
    background-image: url(./images/index_bg3.jpg);
  }
}

@media (max-width: 1024px) {
  body {
    background-attachment: fixed;
  }
}

.index-page header .header .logo {
  top: 0.5rem;
  left: 0.8rem;
}

@media (max-width: 1024px) {
  .index-page header .header .logo {
    top: 26px;
  }
}

@media (max-width: 991px) {
  .index-page header .header .logo {
    top: 18px;
  }
}

@media (max-width: 767px) {
  .index-page header .header .logo {
    top: 10px;
  }
}

@media (max-width: 1024px) {
  .index-page header .header .logo {
    left: 44px;
  }
}

@media (max-width: 991px) {
  .index-page header .header .logo {
    left: 32px;
  }
}

@media (max-width: 767px) {
  .index-page header .header .logo {
    left: 20px;
  }
}

.index-page header .header .logo img {
  width: 2.2rem;
}

@media (max-width: 1024px) {
  .index-page header .header .logo img {
    width: 125px;
  }
}

@media (max-width: 991px) {
  .index-page header .header .logo img {
    width: 90px;
  }
}

@media (max-width: 767px) {
  .index-page header .header .logo img {
    width: 60px;
  }
}

#fullpage {
  transition: all .6s;
}

.pc-index {
  position: relative;
  z-index: 1;
  display: block;
}

@media (max-width: 1024px) {
  .pc-index {
    display: none;
  }
}

.pc-index .section1 {
  position: relative;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding-top: 1rem;
  display: flex;
}

.pc-index .section1 .mouse {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translateX(-50%);
}

.pc-index .section1 .mouse img {
  display: block;
  height: auto;
  width: 0.16rem;
}

.pc-index .section1 .mouse::after {
  content: '';
  display: block;
  background-color: #5dbc8a;
  position: absolute;
  animation: mouse 1.5s infinite;
  width: 0.02rem;
  height: 0.05rem;
  top: 0.04rem;
  left: calc(50% - .01rem);
}

@media (max-width: 1024px) {
  .pc-index .section1 .mouse::after {
    width: 2px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section1 .mouse::after {
    height: 5px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section1 .mouse::after {
    top: 4px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section1 .mouse::after {
    left: calc(50% - 1px);
  }
}

.pc-index .section1 .left {
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 0.8rem;
  padding-right: 0.7rem;
  width: 50%;
}

@media (max-width: 1024px) {
  .pc-index .section1 .left {
    padding-left: 40px;
  }
}

@media (max-width: 767px) {
  .pc-index .section1 .left {
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section1 .left {
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .pc-index .section1 .left {
    padding-right: 20px;
  }
}

.pc-index .section1 .left h1 {
  font-family: 'Seed-bold';
  background-image: linear-gradient(to right, #25b170, #49acb6, #49acb6);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 0.9rem;
}

@media (max-width: 1024px) {
  .pc-index .section1 .left h1 {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  .pc-index .section1 .left h1 {
    font-size: 50px;
  }
}

.pc-index .section1 .left .desc {
  color: #085039;
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .pc-index .section1 .left .desc {
    font-size: 18px;
  }
}

.pc-index .section1 .left .link {
  margin-top: 0.4rem;
}

@media (max-width: 1024px) {
  .pc-index .section1 .left .link {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .pc-index .section1 .left .link {
    margin-top: 20px;
  }
}

.pc-index .section1 .right {
  flex-shrink: 0;
  position: relative;
  height: 75.5vh;
  width: 50%;
}

.pc-index .section1 .right .swiper {
  height: 100%;
  overflow: hidden;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 0.3rem;
}

.pc-index .section1 .right .swiper .swiper-slide {
  background-position: center;
  background-size: cover;
}

.pc-index .section1 .right .swiper .swiper-pagination {
  text-align: right;
  padding-right: 0.7rem;
  bottom: -6%;
}

@media (max-width: 1024px) {
  .pc-index .section1 .right .swiper .swiper-pagination {
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .pc-index .section1 .right .swiper .swiper-pagination {
    padding-right: 20px;
  }
}

.pc-index .section1 .right .swiper .swiper-pagination .swiper-pagination-bullet {
  position: relative;
  background: #25b171;
  opacity: 1;
  width: 0.06rem;
  height: 0.06rem;
  margin: 0 0.06rem;
}

@media (max-width: 1024px) {
  .pc-index .section1 .right .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 6px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section1 .right .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 6px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section1 .right .swiper .swiper-pagination .swiper-pagination-bullet {
    margin: 0 8px;
  }
}

.pc-index .section1 .right .swiper .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  border-radius: 50%;
  border: solid #7bd3ab;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.4s;
  border-width: 0.01rem;
  width: 0.16rem;
  height: 0.16rem;
}

@media (max-width: 1024px) {
  .pc-index .section1 .right .swiper .swiper-pagination .swiper-pagination-bullet::after {
    border-width: 1px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section1 .right .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 16px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section1 .right .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 16px;
  }
}

.pc-index .section1 .right .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  opacity: 1;
}

.pc-index .section1 .leaf-cont.show .leaf {
  opacity: 1;
}

.pc-index .section1 .leaf-cont.show .leaf.leaf1 img {
  transition: transform 3s;
  transform: translateX(0) translateY(0);
}

.pc-index .section1 .leaf-cont.show .leaf.leaf2 img {
  transition: transform 5s;
  transform: translateX(0) translateY(0);
}

.pc-index .section1 .leaf-cont.show .leaf.leaf3 img {
  transition: transform 5s;
  transform: translateX(0) translateY(0);
}

.pc-index .section1 .leaf-cont.next .leaf.leaf1 img {
  transform: translateY(50%);
  transition: transform 3s;
}

.pc-index .section1 .leaf-cont.next .leaf.leaf2 img {
  transform: translateY(50%);
  transition: transform 2s;
}

.pc-index .section1 .leaf-cont.next .leaf.leaf3 img {
  transform: translateY(50%);
  transition: transform 1s;
}

.pc-index .section1 .leaf-cont.trans .leaf.leaf1 img {
  transition: transform 1s;
  transform: translateX(-500%) translateY(50%);
}

.pc-index .section1 .leaf-cont.trans .leaf.leaf2 img {
  transition: transform 1s;
  transform: translateX(-500%) translateY(50%);
}

.pc-index .section1 .leaf-cont.trans .leaf.leaf3 img {
  transition: transform 1s;
  transform: translateX(-500%) translateY(50%);
}

.pc-index .section1 .leaf-cont .leaf {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 1s linear,transform .2s linear;
}

.pc-index .section1 .leaf-cont .leaf img {
  display: block;
  height: auto;
}

.pc-index .section1 .leaf-cont .leaf.leaf1 {
  top: 10%;
  left: 35%;
}

.pc-index .section1 .leaf-cont .leaf.leaf1 img {
  transform: translateX(-500%) translateY(-500%);
  width: 1.12rem;
}

@media (max-width: 1024px) {
  .pc-index .section1 .leaf-cont .leaf.leaf1 img {
    width: 70px;
  }
}

@media (max-width: 767px) {
  .pc-index .section1 .leaf-cont .leaf.leaf1 img {
    width: 60px;
  }
}

.pc-index .section1 .leaf-cont .leaf.leaf2 {
  top: 36%;
  left: 44%;
}

.pc-index .section1 .leaf-cont .leaf.leaf2 img {
  transform: translateX(400%) translateY(-300%);
  width: 1.87rem;
}

@media (max-width: 1024px) {
  .pc-index .section1 .leaf-cont .leaf.leaf2 img {
    width: 90px;
  }
}

@media (max-width: 767px) {
  .pc-index .section1 .leaf-cont .leaf.leaf2 img {
    width: 80px;
  }
}

.pc-index .section1 .leaf-cont .leaf.leaf3 {
  top: 88%;
  left: 19%;
}

.pc-index .section1 .leaf-cont .leaf.leaf3 img {
  transform: translateX(-300%) translateY(-500%);
  width: 2.21rem;
}

@media (max-width: 1024px) {
  .pc-index .section1 .leaf-cont .leaf.leaf3 img {
    width: 100px;
  }
}

@media (max-width: 767px) {
  .pc-index .section1 .leaf-cont .leaf.leaf3 img {
    width: 90px;
  }
}

.pc-index .section2 {
  position: relative;
}

.pc-index .section2.active .default-show .left .steps ul li {
  opacity: 1;
}

.pc-index .section2.active .default-show .left .steps ul li:nth-child(1) {
  transition-delay: .2s;
}

.pc-index .section2.active .default-show .left .steps ul li:nth-child(2) {
  transition-delay: .5s;
  transform: translateX(0) translateY(-50%);
}

.pc-index .section2.active .default-show .left .steps ul li:nth-child(3) {
  transition-delay: .8s;
  transform: translateX(0) translateY(-110%);
}

.pc-index .section2.active .default-show .left .steps ul li:nth-child(4) {
  transition-delay: 1.1s;
  transform: translateX(0) translateY(-150%);
}

.pc-index .section2.show-detail .default-show, .pc-index .section2.show-detail .detail-cont {
  transform: translateX(-100%);
}

.pc-index .section2 .more-btn {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2;
  cursor: pointer;
  transform: translate3d(50%, -50%, 0);
  opacity: 1;
  visibility: visible;
  transition: all 1s;
}

.pc-index .section2 .more-btn.hide {
  opacity: 0;
  visibility: hidden;
}

.pc-index .section2 .more-btn::after, .pc-index .section2 .more-btn::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
}

.pc-index .section2 .more-btn::before {
  animation: scale 3.2s infinite ease-out;
  animation-delay: .8s;
}

.pc-index .section2 .more-btn::after {
  animation: scale 3.2s infinite ease-out;
  animation-delay: 1.6s;
}

.pc-index .section2 .more-btn .icon {
  border-radius: 50%;
  background-color: #257f62;
  cursor: pointer;
  position: relative;
  width: 1.18rem;
  height: 1.18rem;
}

@media (max-width: 1024px) {
  .pc-index .section2 .more-btn .icon {
    width: 118px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section2 .more-btn .icon {
    height: 118px;
  }
}

.pc-index .section2 .more-btn .icon .iconfont {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  font-size: 0.46rem;
}

@media (max-width: 1024px) {
  .pc-index .section2 .more-btn .icon .iconfont {
    font-size: 46px;
  }
}

.pc-index .section2 .more-btn .icon .iconfont::before {
  margin-left: 0.08rem;
}

@media (max-width: 1024px) {
  .pc-index .section2 .more-btn .icon .iconfont::before {
    margin-left: 5px;
  }
}

.pc-index .section2 .more-btn .icon::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
  animation: scale 3.2s infinite ease-out;
  animation-delay: 2.4s;
}

.pc-index .section2 .more-btn .icon::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 50%;
  animation: scale 3.2s infinite ease-out;
}

.pc-index .section2 .more-btn span {
  position: absolute;
  top: 50%;
  right: 110%;
  white-space: nowrap;
  color: #257f62;
  font-family: 'Seed-bold';
  z-index: 1;
  transform: translateY(-50%);
  font-size: 0.2rem;
}

@media (max-width: 1024px) {
  .pc-index .section2 .more-btn span {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .pc-index .section2 .more-btn span {
    font-size: 16px;
  }
}

.pc-index .section2 .default-show {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.pc-index .section2 .default-show::after {
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background: url(./images/section2_bg_op.png) no-repeat center center;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 2rem;
}

.pc-index .section2 .default-show .left, .pc-index .section2 .default-show .right {
  flex-shrink: 0;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  width: 50%;
}

.pc-index .section2 .default-show .left {
  color: #085039;
  padding-left: 1.45rem;
  padding-right: 1rem;
}

@media (max-width: 1024px) {
  .pc-index .section2 .default-show .left {
    padding-left: 40px;
  }
}

@media (max-width: 767px) {
  .pc-index .section2 .default-show .left {
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .pc-index .section2 .default-show .left {
    padding-right: 40px;
  }
}

@media (max-width: 767px) {
  .pc-index .section2 .default-show .left {
    padding-right: 20px;
  }
}

.pc-index .section2 .default-show .left h1 {
  font-family: 'Seed-bold';
  background-image: linear-gradient(to right, #25b170, #49acb6, #49acb6);
  -webkit-background-clip: text;
  color: transparent;
  line-height: 1;
  font-size: 0.9rem;
}

.pc-index .section2 .default-show .left h2 {
  font-family: 'Seed-bold';
  line-height: 1.4;
  font-size: 0.32rem;
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
}

.pc-index .section2 .default-show .left h3 {
  font-family: 'Seed-bold';
  font-size: 0.22rem;
  margin-bottom: 0.3rem;
}

.pc-index .section2 .default-show .left .desc {
  font-size: 0.2rem;
}

.pc-index .section2 .default-show .left .steps {
  margin-top: 2.3rem;
}

.pc-index .section2 .default-show .left .steps ul {
  display: flex;
  align-items: flex-end;
}

.pc-index .section2 .default-show .left .steps ul li {
  box-sizing: border-box;
  border-radius: .13rem;
  color: #085039;
  line-height: 1;
  flex-shrink: 0;
  opacity: 0;
  transition: all 0.7s;
  transition-delay: 0;
  width: 38%;
}

.pc-index .section2 .default-show .left .steps ul li span {
  display: block;
  font-family: 'Seed-bold';
  margin-bottom: .05rem;
  white-space: nowrap;
}

.pc-index .section2 .default-show .left .steps ul li:not(:first-child) {
  margin-left: -13%;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(1) {
  width: 30%;
  background-color: rgba(255, 255, 255, 0.4);
  padding-left: 0.15rem;
  padding-right: 0.15rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-size: 0.15rem;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(2) {
  width: 32%;
  background-color: rgba(161, 227, 199, 0.8);
  transform: translateX(-10%) translateY(-20%);
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-top: 0.3rem;
  padding-bottom: 0.2rem;
  font-size: 0.17rem;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(3) {
  width: 40%;
  background-color: rgba(108, 221, 172, 0.8);
  transform: translateX(-10%) translateY(-70%);
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.35rem;
  padding-bottom: 0.23rem;
  font-size: 0.21rem;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(4) {
  width: 44%;
  background-color: rgba(37, 177, 113, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-10%) translateY(-120%);
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(4) span {
  color: #fff;
  font-size: 0.35rem;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(4):hover .icon .wen {
  transform: rotate(90deg);
  opacity: 0;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(4):hover .icon .jian {
  transform: translateX(20px);
  opacity: 1;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(4) .icon {
  position: relative;
  transform: translateY(0.4rem);
  margin-left: 0.7rem;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(4) .icon .wen {
  color: #fff;
  font-family: 'Seed-bold';
  position: absolute;
  bottom: 0;
  right: 0;
  transform-origin: bottom center;
  transition: all 0.4s;
  font-size: 0.84rem;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(4) .icon .wen b {
  display: block;
  animation: swing 2s infinite;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(4) .icon .jian {
  position: absolute;
  bottom: 0;
  right: 0;
  transform-origin: bottom center;
  transform: translateX(-0.2rem);
  opacity: 0;
  transition: all 0.4s;
  margin-bottom: 0.1rem;
}

.pc-index .section2 .default-show .left .steps ul li:nth-child(4) .icon .jian .iconfont {
  color: #fff;
  font-size: 0.6rem;
}

.pc-index .section2 .default-show .right {
  padding-left: 0.65rem;
  padding-right: 2.5rem;
}

.pc-index .section2 .default-show .right ul li {
  color: #085039;
  font-family: 'Seed-bold';
  line-height: 1.2;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.34rem;
}

.pc-index .section2 .default-show .right ul li:not(:last-child) {
  border-bottom: .01rem solid #26b171;
}

.pc-index .section2 .default-show .right ul li span {
  font-family: 'Seed-bold';
  font-size: 0.6rem;
  margin-right: 0.35rem;
}

.pc-index .section2 .default-show .right ul li:nth-child(1) span {
  color: #25b171;
}

.pc-index .section2 .default-show .right ul li:nth-child(2) span {
  color: #2c9d69;
}

.pc-index .section2 .default-show .right ul li:nth-child(3) span {
  color: #21a489;
}

.pc-index .section2 .default-show .right ul li:nth-child(4) span {
  color: #228f99;
}

.pc-index .section2 .detail-cont {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.pc-index .section2 .detail-cont .cont {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  pointer-events: all !important;
}

.pc-index .section2 .detail-cont .cont::-webkit-scrollbar {
  width: 0px;
}

.pc-index .section2 .detail-cont .xscroll-tab {
  position: absolute;
  z-index: 2;
  width: 60%;
  bottom: 75%;
  left: 6%;
}

.pc-index .section2 .detail-cont .xscroll-tab .item {
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: 'Seed-bold';
  color: #085039;
  line-height: 1.16;
  display: flex;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  font-size: 0.56rem;
}

.pc-index .section2 .detail-cont .xscroll-tab .item span {
  font-family: 'Seed-bold';
  margin-right: 0.4rem;
  font-size: 1rem;
}

.pc-index .section2 .detail-cont .xscroll-tab .item.active {
  opacity: 1;
  visibility: visible;
}

.pc-index .section2 .detail-cont .xscroll-cont {
  position: absolute;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 0;
}

.pc-index .section2 .detail-cont .xscroll-cont .swiper {
  height: 100%;
}

.pc-index .section2 .detail-cont .xscroll-cont .item {
  height: 60vh;
  margin-top: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .t {
  color: #085039;
  flex-shrink: 0;
  line-height: 1.5;
  font-size: 0.22rem;
  margin-right: 0.8rem;
  padding-left: 0.2rem;
  width: 40%;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .t h4 {
  font-family: 'Seed-bold';
  font-size: 0.36rem;
  margin-bottom: 0.15rem;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .t h4.hasdian {
  position: relative;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .t h4.hasdian::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #085039;
  position: absolute;
  left: -3%;
  top: calc(50% - .04rem);
  width: 0.08rem;
  height: 0.08rem;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .t p:not(:last-child) {
  margin-bottom: 0.3rem;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .t p.hasdian {
  position: relative;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .t p.hasdian::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #085039;
  position: absolute;
  left: -3%;
  top: 0.12rem;
  width: 0.08rem;
  height: 0.08rem;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .img {
  width: 40%;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .img img {
  display: block;
  width: 100%;
  height: auto;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .img video {
  display: block;
  width: 100%;
  height: auto;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .img.hastext {
  position: relative;
}

.pc-index .section2 .detail-cont .xscroll-cont .item .img.hastext .t {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: right;
  color: #fff;
  font-family: 'Seed-bold';
  background: linear-gradient(top, rgba(8, 80, 57, 0.8), rgba(8, 80, 57, 0));
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  font-size: 0.2rem;
}

.pc-index .section2 .detail-cont .swiper-contral {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 5%;
  padding: 0 8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.pc-index .section2 .detail-cont .swiper-contral .swiper-pagination {
  position: static;
  background-color: #fff;
  height: 0.03rem;
  width: 4.25rem;
}

.pc-index .section2 .detail-cont .swiper-contral .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #085039;
}

.pc-index .section2 .detail-cont .swiper-contral .swiper-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pc-index .section2 .detail-cont .swiper-contral .swiper-btn .swiper-button-prev, .pc-index .section2 .detail-cont .swiper-contral .swiper-btn .swiper-button-next {
  position: static;
  color: #085039;
  width: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
}

.pc-index .section2 .detail-cont .swiper-contral .swiper-btn .swiper-button-prev:hover, .pc-index .section2 .detail-cont .swiper-contral .swiper-btn .swiper-button-next:hover {
  color: #28b175;
}

.pc-index .section2 .detail-cont .swiper-contral .swiper-btn .swiper-button-prev::after, .pc-index .section2 .detail-cont .swiper-contral .swiper-btn .swiper-button-next::after {
  font-size: .4rem;
}

.pc-index .section2 .detail-cont .progress {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  bottom: 8%;
  left: 6%;
  height: 0.03rem;
  width: 4.25rem;
}

.pc-index .section2 .detail-cont .progress div {
  position: absolute;
  height: 100%;
  z-index: 3;
  background-color: #085039;
}

.pc-index .section2 .detail-cont .btn {
  padding: .05rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: .5rem;
  box-shadow: 0px 0px 0.1rem rgba(8, 80, 57, 0.2);
  transition: all 0.4s;
  cursor: pointer;
}

.pc-index .section2 .detail-cont .btn:hover {
  box-shadow: 0px 0px 0.15rem rgba(8, 80, 57, 0.4);
}

.pc-index .section2 .detail-cont .btn .icon {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background-color: #26b171;
  transition: all 0.4s;
  margin-right: 0.15rem;
  width: 0.32rem;
  height: 0.32rem;
}

.pc-index .section2 .detail-cont .btn .icon .go {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background-color: #26b171;
  transform-origin: left center;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  border-radius: 50%;
}

.pc-index .section2 .detail-cont .btn .icon .go .iconfont {
  color: #fff;
  font-size: 0.2rem;
}

.pc-index .section2 .detail-cont .btn .icon .back {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: #085039;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform-origin: right center;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transform: scale(0);
}

.pc-index .section2 .detail-cont .btn .icon .back .iconfont {
  color: #fff;
  font-size: 0.2rem;
}

.pc-index .section2 .detail-cont .btn .text {
  position: relative;
  font-family: 'Seed-bold';
  overflow: hidden;
  line-height: 1;
  margin-right: 0.2rem;
}

.pc-index .section2 .detail-cont .btn .text .go {
  color: #26b171;
  transition: all 0.4s;
  position: absolute;
  top: 0;
  left: 0;
}

.pc-index .section2 .detail-cont .btn .text .back {
  color: #085039;
  transform: translateY(100%);
  transition: all 0.4s;
}

.pc-index .section2 .detail-cont .btn.back-btn .icon {
  background-color: #085039;
}

.pc-index .section2 .detail-cont .btn.back-btn .icon .go {
  transform: scale(0);
}

.pc-index .section2 .detail-cont .btn.back-btn .icon .back {
  transform: scale(1);
}

.pc-index .section2 .detail-cont .btn.back-btn .text .go {
  transform: translateY(-100%);
}

.pc-index .section2 .detail-cont .btn.back-btn .text .back {
  transform: translateY(0);
}

.pc-index .section2 .leaf-cont.show .leaf {
  opacity: 1;
}

.pc-index .section2 .leaf-cont.show .leaf.leaf4 img {
  transition: transform 4s;
  transform: translateX(0) translateY(0);
}

.pc-index .section2 .leaf-cont.show .leaf.leaf5 img {
  transition: transform 5s;
  transform: translateX(0) translateY(0);
}

.pc-index .section2 .leaf-cont.trans .leaf.leaf4 img {
  transition: transform 1s;
  transform: translateX(-500%);
}

.pc-index .section2 .leaf-cont.trans .leaf.leaf5 img {
  transition: transform 1s;
  transform: translateX(-500%);
}

.pc-index .section2 .leaf-cont.next .leaf.leaf4 img {
  transform: translateY(50%);
  transition: transform 3s;
}

.pc-index .section2 .leaf-cont.next .leaf.leaf5 img {
  transform: translateY(50%);
  transition: transform 2s;
}

.pc-index .section2 .leaf-cont .leaf {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 1s linear,transform .2s linear;
}

.pc-index .section2 .leaf-cont .leaf img {
  display: block;
  height: auto;
  transition: transform 1s;
}

.pc-index .section2 .leaf-cont .leaf.leaf4 {
  top: 8%;
  left: 86%;
  z-index: -1;
}

.pc-index .section2 .leaf-cont .leaf.leaf4 img {
  transform: translateX(-100%) translateY(-100%);
  width: 1.96rem;
}

.pc-index .section2 .leaf-cont .leaf.leaf5 {
  top: 70%;
  left: 75%;
  z-index: -1;
}

.pc-index .section2 .leaf-cont .leaf.leaf5 img {
  transform: translateX(300%) translateY(-500%);
  width: 2.49rem;
}

.pc-index .section3 {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-left: 1.45rem;
  padding-right: 1.45rem;
}

.pc-index .section3 .left {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  margin-right: 1.8rem;
}

.pc-index .section3 .left h1 {
  font-family: 'Seed-bold';
  background-image: linear-gradient(to right, #25b170, #49acb6, #49acb6);
  -webkit-background-clip: text;
  color: transparent;
  line-height: 1;
  font-size: 0.9rem;
  margin-bottom: 0.4rem;
}

.pc-index .section3 .left .link .common-link {
  width: 2.4rem;
}

.pc-index .section3 .right {
  flex-grow: 1;
  position: relative;
  z-index: 2;
}

.pc-index .section3 .right .pro1 {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0.4rem;
}

.pc-index .section3 .right .pro1 > div {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.pc-index .section3 .right .pro1 li {
  padding: .05rem;
  border-radius: .5rem;
  background: linear-gradient(top, #c3f4ce, #a4ddce);
  transition: all 0.4s;
  width: 3rem;
  margin-bottom: 0.15rem;
}

.pc-index .section3 .right .pro1 li:not(:last-child) {
  margin-right: 0.15rem;
}

.pc-index .section3 .right .pro1 li:hover {
  box-shadow: .2rem -.2rem .4rem #88c9b8 inset;
}

.pc-index .section3 .right .pro1 li:hover a .icon {
  background-color: #ffffff;
}

.pc-index .section3 .right .pro1 li a {
  display: flex;
  align-items: center;
  color: #085039;
  font-family: 'Seed-bold';
  white-space: nowrap;
  font-size: 0.23rem;
}

.pc-index .section3 .right .pro1 li a .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .5rem;
  background-color: #e2f7eb;
  flex-shrink: 0;
  transition: all 0.4s;
}

.pc-index .section3 .right .pro1 li a .icon img {
  display: block;
  height: auto;
  width: 0.5rem;
}

.pc-index .section3 .right .pro1 li a p {
  width: calc(100%-.5rem);
  text-align: center;
}

.pc-index .section3 .right .pro2 {
  border-top: .01rem solid #21653b;
}

.pc-index .section3 .right .pro2 li {
  border-bottom: .01rem solid #21653b;
}

.pc-index .section3 .right .pro2 li:hover a .title span::after {
  transform: scaleX(1);
}

.pc-index .section3 .right .pro2 li:hover a .desc {
  color: #28b175;
}

.pc-index .section3 .right .pro2 li a {
  align-items: flex-start;
  justify-content: flex-start;
  color: #085039;
  display: flex;
  padding-top: 0.15rem;
  padding-bottom: 0.1rem;
}

.pc-index .section3 .right .pro2 li a .title {
  flex-shrink: 0;
  font-family: 'Seed-bold';
  font-size: 0.26rem;
  width: 34%;
}

.pc-index .section3 .right .pro2 li a .title span {
  position: relative;
}

.pc-index .section3 .right .pro2 li a .title span::after {
  content: '';
  display: block;
  width: 100%;
  background-color: #085039;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.4s;
  transform-origin: left;
  transform: scaleX(0);
  height: 0.02rem;
}

.pc-index .section3 .right .pro2 li a .desc {
  flex-shrink: 0;
  transition: all 0.4s;
  font-size: 0.17rem;
  width: 66%;
}

.pc-index .section3 .right .pro2 li a .desc span {
  display: block;
  width: 50%;
  float: left;
}

.pc-index .section3 .fixed-img .circle-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform .2s linear;
}

.pc-index .section3 .fixed-img .circle-img img {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: auto;
}

.pc-index .section3 .fixed-img .circle-img.img1 {
  top: 5%;
  left: 16%;
  width: 3.3rem;
}

.pc-index .section3 .fixed-img .circle-img.img2 {
  top: 45%;
  left: 93%;
  width: 2.68rem;
}

.pc-index .section3 .fixed-img .circle-img.img3 {
  top: 84%;
  left: 42%;
  width: 1.98rem;
}

.pc-index .section3 .leaf-cont.show .leaf {
  opacity: 1;
}

.pc-index .section3 .leaf-cont.show .leaf.leaf6 img {
  transition: transform 3s;
  transform: translateX(0) translateY(0);
}

.pc-index .section3 .leaf-cont.show .leaf.leaf7 img {
  transition: transform 5s;
  transform: translateX(0) translateY(0);
}

.pc-index .section3 .leaf-cont.show .leaf.leaf8 img {
  transition: transform 5s;
  transform: translateX(0) translateY(0);
}

.pc-index .section3 .leaf-cont.next .leaf.leaf6 img {
  transform: translateY(50%);
  transition: transform 3s;
}

.pc-index .section3 .leaf-cont.next .leaf.leaf7 img {
  transform: translateY(50%);
  transition: transform 2s;
}

.pc-index .section3 .leaf-cont.next .leaf.leaf8 img {
  transform: translateY(50%);
  transition: transform 1s;
}

.pc-index .section3 .leaf-cont .leaf {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 1s linear,transform .2s linear;
}

.pc-index .section3 .leaf-cont .leaf > div {
  transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1);
}

.pc-index .section3 .leaf-cont .leaf img {
  display: block;
  height: auto;
  transform-origin: center;
  transition: all .6s;
}

.pc-index .section3 .leaf-cont .leaf.leaf6 {
  top: 13%;
  left: 3%;
}

.pc-index .section3 .leaf-cont .leaf.leaf6 img {
  transform: translateX(-200%) translateY(-300%);
  width: 1.41rem;
}

.pc-index .section3 .leaf-cont .leaf.leaf7 {
  top: 33%;
  left: 87%;
  z-index: -1;
}

.pc-index .section3 .leaf-cont .leaf.leaf7 img {
  transform: translateX(100%) translateY(-300%);
  width: 1.87rem;
}

.pc-index .section3 .leaf-cont .leaf.leaf8 {
  top: 77%;
  left: 19%;
}

.pc-index .section3 .leaf-cont .leaf.leaf8 img {
  transform: translateX(-100%) translateY(-500%);
  width: 1.12rem;
}

.mob-index {
  display: none;
}

@media (max-width: 1024px) {
  .mob-index {
    display: block;
  }
}

.mob-index .section1 .swiper {
  overflow: hidden;
  position: relative;
  height: 600px;
}

@media (max-width: 991px) {
  .mob-index .section1 .swiper {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .swiper {
    height: 300px;
  }
}

.mob-index .section1 .swiper .swiper-slide {
  background-position: center;
  background-size: cover;
}

.mob-index .section1 .swiper .swiper-pagination {
  text-align: right;
  padding-right: 70px;
  bottom: 20px;
}

@media (max-width: 1600px) {
  .mob-index .section1 .swiper .swiper-pagination {
    padding-right: 60px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section1 .swiper .swiper-pagination {
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section1 .swiper .swiper-pagination {
    padding-right: 40px;
  }
}

@media (max-width: 991px) {
  .mob-index .section1 .swiper .swiper-pagination {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .swiper .swiper-pagination {
    padding-right: 20px;
  }
}

.mob-index .section1 .swiper .swiper-pagination .swiper-pagination-bullet {
  margin: 0 6px;
  width: 6px;
  height: 6px;
  position: relative;
  background: #25b171;
  opacity: 1;
}

.mob-index .section1 .swiper .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  border-radius: 50%;
  border: 1px solid #7bd3ab;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0);
  transition: all 0.4s;
  width: 16px;
  height: 16px;
}

.mob-index .section1 .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  opacity: 1;
}

.mob-index .section1 .left {
  flex-shrink: 0;
  box-sizing: border-box;
  padding-left: 80px;
  padding-right: 70px;
  margin-bottom: 160px;
  margin-top: 50px;
}

@media (max-width: 1600px) {
  .mob-index .section1 .left {
    padding-left: 68px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section1 .left {
    padding-left: 56px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section1 .left {
    padding-left: 44px;
  }
}

@media (max-width: 991px) {
  .mob-index .section1 .left {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .left {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section1 .left {
    padding-right: 60px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section1 .left {
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section1 .left {
    padding-right: 40px;
  }
}

@media (max-width: 991px) {
  .mob-index .section1 .left {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .left {
    padding-right: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section1 .left {
    margin-bottom: 138px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section1 .left {
    margin-bottom: 116px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section1 .left {
    margin-bottom: 94px;
  }
}

@media (max-width: 991px) {
  .mob-index .section1 .left {
    margin-bottom: 72px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .left {
    margin-bottom: 50px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section1 .left {
    margin-top: 44px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section1 .left {
    margin-top: 38px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section1 .left {
    margin-top: 32px;
  }
}

@media (max-width: 991px) {
  .mob-index .section1 .left {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .left {
    margin-top: 20px;
  }
}

.mob-index .section1 .left h1 {
  font-family: 'Seed-bold';
  background-image: linear-gradient(to right, #25b170, #49acb6, #49acb6);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 90px;
}

@media (max-width: 767px) {
  .mob-index .section1 .left h1 {
    text-align: center;
  }
}

@media (max-width: 1600px) {
  .mob-index .section1 .left h1 {
    font-size: 80px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section1 .left h1 {
    font-size: 70px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section1 .left h1 {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .mob-index .section1 .left h1 {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .left h1 {
    font-size: 40px;
  }
}

.mob-index .section1 .left .desc {
  color: #085039;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .mob-index .section1 .left .desc {
    font-size: 19.6px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section1 .left .desc {
    font-size: 19.2px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section1 .left .desc {
    font-size: 18.8px;
  }
}

@media (max-width: 991px) {
  .mob-index .section1 .left .desc {
    font-size: 18.4px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .left .desc {
    font-size: 18px;
  }
}

.mob-index .section1 .left .link {
  margin-top: 40px;
}

@media (max-width: 1600px) {
  .mob-index .section1 .left .link {
    margin-top: 36px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section1 .left .link {
    margin-top: 32px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section1 .left .link {
    margin-top: 28px;
  }
}

@media (max-width: 991px) {
  .mob-index .section1 .left .link {
    margin-top: 24px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .left .link {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .mob-index .section1 .left .link .common-link {
    margin: 0 auto;
  }
}

.mob-index .section2 {
  margin-bottom: 150px;
}

@media (max-width: 1600px) {
  .mob-index .section2 {
    margin-bottom: 130px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 {
    margin-bottom: 110px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 {
    margin-bottom: 90px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 {
    margin-bottom: 70px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 {
    margin-bottom: 50px;
  }
}

.mob-index .section2 .left {
  color: #085039;
  padding-left: 80px;
  padding-right: 70px;
  margin-bottom: 80px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left {
    padding-left: 68px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left {
    padding-left: 56px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left {
    padding-left: 44px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left {
    padding-right: 60px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left {
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left {
    padding-right: 40px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left {
    padding-right: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left {
    margin-bottom: 74px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left {
    margin-bottom: 68px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left {
    margin-bottom: 62px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left {
    margin-bottom: 56px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left {
    margin-bottom: 50px;
  }
}

.mob-index .section2 .left h1 {
  font-family: 'Seed-bold';
  background-image: linear-gradient(to right, #25b170, #49acb6, #49acb6);
  -webkit-background-clip: text;
  color: transparent;
  line-height: 1;
  font-size: 90px;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .mob-index .section2 .left h1 {
    text-align: center;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left h1 {
    font-size: 80px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left h1 {
    font-size: 70px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left h1 {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left h1 {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left h1 {
    font-size: 40px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left h1 {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left h1 {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left h1 {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left h1 {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left h1 {
    margin-bottom: 20px;
  }
}

.mob-index .section2 .left h2 {
  font-family: 'Seed-bold';
  line-height: 1.4;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  font-size: 50px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left h2 {
    font-size: 44px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left h2 {
    font-size: 38px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left h2 {
    font-size: 32px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left h2 {
    font-size: 26px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left h2 {
    font-size: 20px;
  }
}

.mob-index .section2 .left h3 {
  font-family: 'Seed-bold';
  font-size: 22px;
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left h3 {
    font-size: 21.2px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left h3 {
    font-size: 20.4px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left h3 {
    font-size: 19.6px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left h3 {
    font-size: 18.8px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left h3 {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left h3 {
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left h3 {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left h3 {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left h3 {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left h3 {
    margin-bottom: 15px;
  }
}

.mob-index .section2 .left .desc {
  font-size: 20px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .desc {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .desc {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .desc {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .desc {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .desc {
    font-size: 16px;
  }
}

.mob-index .section2 .left .steps {
  margin-top: 250px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps {
    margin-top: 224px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps {
    margin-top: 198px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps {
    margin-top: 172px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps {
    margin-top: 146px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps {
    margin-top: 120px;
  }
}

.mob-index .section2 .left .steps ul {
  display: flex;
  align-items: flex-end;
}

.mob-index .section2 .left .steps ul li {
  box-sizing: border-box;
  border-radius: 13px;
  color: #085039;
  line-height: 1;
  flex-shrink: 0;
  opacity: 0;
  transition: all 0.4s;
  transition-delay: 0;
  width: 38%;
}

.mob-index .section2 .left .steps ul li span {
  display: block;
  font-family: 'Seed-bold';
  margin-bottom: 5px;
  white-space: nowrap;
}

.mob-index .section2 .left .steps ul li:not(:first-child) {
  margin-left: -13%;
}

.mob-index .section2 .left .steps ul li:nth-child(1) {
  width: 30%;
  background-color: rgba(185, 223, 218, 0.6);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 15px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-left: 14px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-left: 13px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-left: 12px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-left: 11px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-right: 14px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-right: 13px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-right: 12px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-right: 11px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-top: 27px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-top: 24px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-top: 21px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-top: 18px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    padding-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    font-size: 14.4px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    font-size: 13.8px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    font-size: 13.2px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    font-size: 12.6px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(1) {
    font-size: 12px;
  }
}

.mob-index .section2 .left .steps ul li:nth-child(2) {
  width: 32%;
  background-color: rgba(161, 227, 199, 0.8);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 17px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-right: 18px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-right: 16px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-right: 14px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-top: 27px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-top: 24px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-top: 21px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-top: 18px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-bottom: 19px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-bottom: 18px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-bottom: 17px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    padding-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    font-size: 16px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    font-size: 15px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    font-size: 14px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(2) {
    font-size: 12px;
  }
}

.mob-index .section2 .left .steps ul li:nth-child(3) {
  width: 40%;
  background-color: rgba(108, 221, 172, 0.8);
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 35px;
  padding-bottom: 30px;
  font-size: 21px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-left: 23px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-left: 21px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-left: 19px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-left: 17px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-right: 23px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-right: 21px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-right: 19px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-right: 17px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-top: 31px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-top: 27px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-top: 23px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-top: 19px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    padding-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    font-size: 19.4px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    font-size: 17.8px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    font-size: 16.2px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    font-size: 14.6px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(3) {
    font-size: 13px;
  }
}

.mob-index .section2 .left .steps ul li:nth-child(4) {
  width: 40%;
  background-color: rgba(37, 177, 113, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-left: 27px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-left: 24px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-left: 21px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-left: 18px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-right: 27px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-right: 24px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-right: 21px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-right: 18px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-top: 36px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-top: 32px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-top: 28px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-top: 24px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) {
    padding-bottom: 20px;
  }
}

.mob-index .section2 .left .steps ul li:nth-child(4) span {
  color: #fff;
  font-size: 35px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) span {
    font-size: 32px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) span {
    font-size: 29px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) span {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) span {
    font-size: 23px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps ul li:nth-child(4) span {
    font-size: 20px;
  }
}

.mob-index .section2 .left .steps ul li:nth-child(4):hover .icon .wen {
  transform: rotate(90deg);
  opacity: 0;
}

.mob-index .section2 .left .steps ul li:nth-child(4):hover .icon .jian {
  transform: translateX(20px);
  opacity: 1;
}

.mob-index .section2 .left .steps.active ul li {
  opacity: 1;
}

.mob-index .section2 .left .steps.active ul li:nth-child(1) {
  transition-delay: .3s;
}

.mob-index .section2 .left .steps.active ul li:nth-child(2) {
  transition-delay: .7s;
  transform: translateY(-50%);
}

.mob-index .section2 .left .steps.active ul li:nth-child(3) {
  transition-delay: 1.1s;
  transform: translateY(-120%);
}

.mob-index .section2 .left .steps.active ul li:nth-child(4) {
  transition-delay: 1.5s;
  transform: translateY(-180%);
}

@media (max-width: 767px) {
  .mob-index .section2 .left .steps.active ul li:nth-child(4) {
    transform: translateY(-240%);
  }
}

.mob-index .section2 .right {
  padding-left: 80px;
  padding-right: 70px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right {
    padding-left: 68px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right {
    padding-left: 56px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right {
    padding-left: 44px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right {
    padding-right: 60px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right {
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right {
    padding-right: 40px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right {
    padding-right: 20px;
  }
}

.mob-index .section2 .right ul li {
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li {
    margin-bottom: 48px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li {
    margin-bottom: 46px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li {
    margin-bottom: 44px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li {
    margin-bottom: 42px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li {
    margin-bottom: 40px;
  }
}

.mob-index .section2 .right ul li .title {
  font-family: 'Seed-bold';
  color: #085039;
  line-height: 1.16;
  display: flex;
  align-items: flex-start;
  font-size: 56px;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .title {
    font-size: 48.8px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .title {
    font-size: 41.6px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .title {
    font-size: 34.4px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .title {
    font-size: 27.2px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .title {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .title {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .title {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .title {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .title {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .title {
    margin-bottom: 20px;
  }
}

.mob-index .section2 .right ul li .title span {
  font-family: 'Seed-bold';
  margin-right: 40px;
  font-size: 100px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .title span {
    margin-right: 36px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .title span {
    margin-right: 32px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .title span {
    margin-right: 28px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .title span {
    margin-right: 24px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .title span {
    margin-right: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .title span {
    font-size: 88px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .title span {
    font-size: 76px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .title span {
    font-size: 64px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .title span {
    font-size: 52px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .title span {
    font-size: 40px;
  }
}

.mob-index .section2 .right ul li .desc .t {
  color: #085039;
  flex-shrink: 0;
  line-height: 1.5;
  font-size: 22px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t {
    font-size: 20.8px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t {
    font-size: 16px;
  }
}

.mob-index .section2 .right ul li .desc .t h4 {
  font-family: 'Seed-bold';
  font-size: 36px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    font-size: 32.8px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    font-size: 29.6px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    font-size: 26.4px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    font-size: 23.2px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    margin-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    margin-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    margin-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t h4 {
    margin-bottom: 10px;
  }
}

.mob-index .section2 .right ul li .desc .t h4.hasdian {
  position: relative;
  padding-left: 1px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian {
    padding-left: 1.8px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian {
    padding-left: 2.6px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian {
    padding-left: 3.4px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian {
    padding-left: 4.2px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian {
    padding-left: 5px;
  }
}

.mob-index .section2 .right ul li .desc .t h4.hasdian::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #085039;
  position: absolute;
  left: -2%;
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    width: 7.4px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    width: 6.8px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    width: 6.2px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    width: 5.6px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    width: 5px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    height: 7.4px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    height: 6.8px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    height: 6.2px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    height: 5.6px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t h4.hasdian::after {
    height: 5px;
  }
}

.mob-index .section2 .right ul li .desc .t p:not(:last-child) {
  margin-bottom: 30px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t p:not(:last-child) {
    margin-bottom: 27px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t p:not(:last-child) {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t p:not(:last-child) {
    margin-bottom: 21px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t p:not(:last-child) {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t p:not(:last-child) {
    margin-bottom: 15px;
  }
}

.mob-index .section2 .right ul li .desc .t p.hasdian {
  position: relative;
  padding-left: 1px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian {
    padding-left: 1.8px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian {
    padding-left: 2.6px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian {
    padding-left: 3.4px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian {
    padding-left: 4.2px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian {
    padding-left: 5px;
  }
}

.mob-index .section2 .right ul li .desc .t p.hasdian::after {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: #085039;
  position: absolute;
  left: -3%;
  top: 12px;
  width: 8px;
  height: 8px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    top: 11.6px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    top: 11.2px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    top: 10.8px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    top: 10.4px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    top: 10px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    width: 7.4px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    width: 6.8px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    width: 6.2px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    width: 5.6px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    width: 5px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    height: 7.4px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    height: 6.8px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    height: 6.2px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    height: 5.6px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .t p.hasdian::after {
    height: 5px;
  }
}

.mob-index .section2 .right ul li .desc .img {
  margin-top: 50px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .img {
    margin-top: 44px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .img {
    margin-top: 38px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .img {
    margin-top: 32px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .img {
    margin-top: 26px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .img {
    margin-top: 20px;
  }
}

.mob-index .section2 .right ul li .desc .img img {
  display: block;
  width: 100%;
  height: auto;
}

.mob-index .section2 .right ul li .desc .img video {
  display: block;
  width: 100%;
  height: auto;
}

.mob-index .section2 .right ul li .desc .img.hastext {
  position: relative;
}

.mob-index .section2 .right ul li .desc .img.hastext .t {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: right;
  color: #fff;
  font-family: 'Seed-bold';
  background: linear-gradient(top, rgba(8, 80, 57, 0.8), rgba(8, 80, 57, 0));
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 60px;
  padding-right: 60px;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-top: 18px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-top: 16px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-top: 14px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-top: 12px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-left: 52px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-left: 44px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-left: 36px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-left: 28px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-right: 52px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-right: 44px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-right: 36px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-right: 28px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    padding-right: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .mob-index .section2 .right ul li .desc .img.hastext .t {
    font-size: 16px;
  }
}

.mob-index .section3 {
  padding-left: 80px;
  padding-right: 70px;
  margin-bottom: 100px;
}

@media (max-width: 1600px) {
  .mob-index .section3 {
    padding-left: 68px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 {
    padding-left: 56px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 {
    padding-left: 44px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 {
    padding-right: 60px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 {
    padding-right: 50px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 {
    padding-right: 40px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 {
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 {
    padding-right: 20px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 {
    margin-bottom: 90px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 {
    margin-bottom: 80px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 {
    margin-bottom: 70px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 {
    margin-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 {
    margin-bottom: 50px;
  }
}

.mob-index .section3 .left {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .mob-index .section3 .left {
    display: block;
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 .left {
    margin-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .left {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .left {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .left {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .left {
    margin-bottom: 20px;
  }
}

.mob-index .section3 .left h1 {
  font-family: 'Seed-bold';
  background-image: linear-gradient(to right, #25b170, #49acb6, #49acb6);
  -webkit-background-clip: text;
  color: transparent;
  line-height: 1;
  font-size: 90px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .mob-index .section3 .left h1 {
    text-align: center;
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 .left h1 {
    font-size: 80px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .left h1 {
    font-size: 70px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .left h1 {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .left h1 {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .left h1 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .left h1 {
    margin-bottom: 20px;
  }
}

.mob-index .section3 .left .link {
  flex-shrink: 0;
  display: block;
}

@media (max-width: 767px) {
  .mob-index .section3 .left .link {
    display: none;
  }
}

.mob-index .section3 .left .link .common-link {
  width: 205px;
}

@media (max-width: 767px) {
  .mob-index .section3 .left .link .common-link {
    margin: 0 auto;
  }
}

.mob-index .section3 .right .pro1 {
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro1 {
    margin-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro1 {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro1 {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro1 {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 {
    width: calc(100% + 20px);
  }
}

.mob-index .section3 .right .pro1 li {
  float: left;
  padding: 5px;
  border-radius: 50px;
  background: linear-gradient(top, #c3f4ce, #a4ddce);
  transition: all 0.4s;
  width: 280px;
  margin-right: 15px;
  margin-bottom: 15px;
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro1 li {
    width: 240px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro1 li {
    width: 220px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 li {
    width: calc(50% - 10px);
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro1 li {
    margin-right: 13px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro1 li {
    margin-right: 11px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro1 li {
    margin-right: 9px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro1 li {
    margin-right: 7px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 li {
    margin-right: 5px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro1 li {
    margin-bottom: 13px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro1 li {
    margin-bottom: 11px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro1 li {
    margin-bottom: 9px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro1 li {
    margin-bottom: 7px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 li {
    margin-bottom: 5px;
  }
}

.mob-index .section3 .right .pro1 li:hover {
  box-shadow: 20px -20px 40px #88c9b8 inset;
}

.mob-index .section3 .right .pro1 li:hover a .icon {
  background-color: #ffffff;
}

.mob-index .section3 .right .pro1 li a {
  display: flex;
  align-items: center;
  color: #085039;
  font-family: 'Seed-bold';
  white-space: nowrap;
  font-size: 16px;
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro1 li a {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 li a {
    font-size: 12px;
  }
}

.mob-index .section3 .right .pro1 li a .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #e2f7eb;
  flex-shrink: 0;
  transition: all 0.4s;
  margin-right: 20px;
  width: 62px;
  height: 62px;
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro1 li a .icon {
    margin-right: 18px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro1 li a .icon {
    margin-right: 16px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro1 li a .icon {
    margin-right: 14px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro1 li a .icon {
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 li a .icon {
    margin-right: 10px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro1 li a .icon {
    width: 57.6px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro1 li a .icon {
    width: 53.2px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro1 li a .icon {
    width: 48.8px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro1 li a .icon {
    width: 44.4px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 li a .icon {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro1 li a .icon {
    height: 57.6px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro1 li a .icon {
    height: 53.2px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro1 li a .icon {
    height: 48.8px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro1 li a .icon {
    height: 44.4px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 li a .icon {
    height: 40px;
  }
}

@media only screen and (width: 1440px) {
  .mob-index .section3 .right .pro1 li a .icon {
    margin-right: 10px;
  }
}

.mob-index .section3 .right .pro1 li a .icon img {
  display: block;
  height: auto;
  width: 50px;
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro1 li a .icon img {
    width: 46px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro1 li a .icon img {
    width: 42px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro1 li a .icon img {
    width: 38px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro1 li a .icon img {
    width: 34px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro1 li a .icon img {
    width: 30px;
  }
}

.mob-index .section3 .right .pro2 {
  border-top: 1px solid #21653b;
}

.mob-index .section3 .right .pro2 li {
  border-bottom: 1px solid #21653b;
}

.mob-index .section3 .right .pro2 li a {
  align-items: flex-start;
  justify-content: flex-start;
  color: #085039;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro2 li a {
    display: block;
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro2 li a {
    padding-top: 14px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro2 li a {
    padding-top: 13px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro2 li a {
    padding-top: 12px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro2 li a {
    padding-top: 11px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro2 li a {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro2 li a {
    padding-bottom: 14px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro2 li a {
    padding-bottom: 13px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro2 li a {
    padding-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro2 li a {
    padding-bottom: 11px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro2 li a {
    padding-bottom: 10px;
  }
}

.mob-index .section3 .right .pro2 li a .title {
  flex-shrink: 0;
  font-family: 'Seed-bold';
  font-size: 26px;
  width: 34%;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro2 li a .title {
    font-size: 24.8px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro2 li a .title {
    font-size: 23.6px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro2 li a .title {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro2 li a .title {
    font-size: 21.2px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro2 li a .title {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro2 li a .title {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro2 li a .title {
    margin-bottom: 5px;
  }
}

.mob-index .section3 .right .pro2 li a .desc {
  flex-shrink: 0;
  font-size: 17px;
  width: 66%;
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro2 li a .desc {
    font-size: 16.4px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro2 li a .desc {
    font-size: 15.8px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro2 li a .desc {
    font-size: 15.2px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro2 li a .desc {
    font-size: 14.6px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro2 li a .desc {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro2 li a .desc {
    width: 100%;
  }
}

.mob-index .section3 .right .pro2 li a .desc span {
  display: block;
  width: 50%;
  float: left;
  line-height: 1.2;
  margin-bottom: 20px;
}

@media (max-width: 1600px) {
  .mob-index .section3 .right .pro2 li a .desc span {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .right .pro2 li a .desc span {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .right .pro2 li a .desc span {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .right .pro2 li a .desc span {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .right .pro2 li a .desc span {
    margin-bottom: 10px;
  }
}

.mob-index .section3 .mob-link {
  margin-top: 50px;
  display: none;
}

@media (max-width: 1600px) {
  .mob-index .section3 .mob-link {
    margin-top: 46px;
  }
}

@media (max-width: 1366px) {
  .mob-index .section3 .mob-link {
    margin-top: 42px;
  }
}

@media (max-width: 1024px) {
  .mob-index .section3 .mob-link {
    margin-top: 38px;
  }
}

@media (max-width: 991px) {
  .mob-index .section3 .mob-link {
    margin-top: 34px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .mob-link {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .mob-index .section3 .mob-link {
    display: block;
  }
}

.mob-index .section3 .mob-link .common-link {
  width: 205px;
}

@media (max-width: 767px) {
  .mob-index .section3 .mob-link .common-link {
    margin: 0 auto;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
    opacity: 1;
    background-color: #64deae;
  }
  100% {
    transform: scale(4);
    opacity: 0;
    background-color: #46b8a2;
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
