@import "../../assets/styles/utils";

footer .footer-nav{
  @include res(display,block,(sm:none));
}

body{
  background-image: url(./images/index_bg2.jpg);
  background-image: url(./images/index_bg3.jpg);
  background-position: center;
  background-size: cover;
  transition: all .6s;
  @include res(background-image,url(./images/index_bg1.jpg),(md:url(./images/index_bg3.jpg)));
  @include res(background-attachment,null,(md:fixed));
}
.index-page{
  header .header .logo{
    @include res(top,.5rem,(md:26px,sm:18px,xs:10px));
    @include res(left,.8rem, (md:44px,sm:32px,xs:20px));
    img{
      @include res(width,2.2rem,(md:125px,sm:90px,xs:60px));
    }
  }
}
#fullpage{
  transition: all .6s;
}



.pc-index{
  position: relative;
  z-index: 1;
  @include res(display,block,(md:none));
  .section1{
    position: relative;
    align-items: center;
    width: 100vw;
    height: 100vh;
    // overflow: hidden;
    @include res(padding-top,1rem);
    @include res(display,flex);
    .mouse{
      position: absolute;
      top: 95%;
      left: 50%;
      transform: translateX(-50%);
      img{
        display: block;
        height: auto;
        @include res(width,.16rem);
      }
      &::after{
        content: '';
        display: block;
        background-color: #5dbc8a;
        position: absolute;
        animation: mouse 1.5s infinite;
        @include res(width,.02rem,(md:2px));
        @include res(height,.05rem,(md:5px));
        @include res(top,.04rem,(md:4px));
        @include res(left,calc(50% - .01rem),(md:calc(50% - 1px)));
      }
    }
    .left{
      flex-shrink: 0;
      box-sizing: border-box;
      @include res(padding-left,.8rem, (md:40px,xs:20px));
      @include res(padding-right,.7rem,(md:40px,xs:20px));
      @include res(width,50%);
      h1{
        font-family: 'Seed-bold';
        background-image: linear-gradient(to right, #25b170, #49acb6,#49acb6);
        -webkit-background-clip: text;
        color: transparent;
        @include res(font-size,.9rem,(md:60px,xs:50px));
      }
      .desc{
        color: $color-main;
        @include res(font-size, .2rem,(md:18px));
      }
      .link{
        @include res(margin-top,.4rem, (md:30px,xs:20px));
      }
    }
    .right{
      flex-shrink: 0;
      position: relative;
      @include res(height,75.5vh);
      @include res(width,50%);
      .swiper{
        height: 100%;
        overflow: hidden;
        @include res(border-top-left-radius,2rem);
        @include res(border-bottom-left-radius,.3rem);
        .swiper-slide{
          background-position: center;
          background-size: cover;
        }
        .swiper-pagination{
          text-align: right;
          @include res(padding-right,.7rem,(md:40px,xs:20px));
          @include res(bottom,-6%);
          .swiper-pagination-bullet{
            position: relative;
            background: #25b171;
            opacity: 1;
            @include res(width,.06rem,(md:6px));
            @include res(height,.06rem,(md:6px));
            @include res(margin,0 .06rem,(md:0 8px));
            &::after{
              content: '';
              display: block;
              border-radius: 50%;
              border: solid #7bd3ab;
              position: absolute;
              top: 50%;
              left:50%;
              opacity: 0;
              transform: translate3d(-50%,-50%,0);
              transition: all $anime-duration;
              @include res(border-width, .01rem,(md:1px));
              @include res(width,.16rem,(md:16px));
              @include res(height,.16rem,(md:16px));
            }
            &.swiper-pagination-bullet-active{
              &::after{
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .leaf-cont{
      &.show{
        .leaf{
          opacity: 1;
          &.leaf1 img{
            transition: transform 3s;
            transform: translateX(0) translateY(0);
          }
          &.leaf2 img{
            transition: transform 5s;
            transform: translateX(0) translateY(0);
          }
          &.leaf3 img{
            transition: transform 5s;
            transform: translateX(0) translateY(0);
          }
        }
      }
      &.next{
        .leaf{
          &.leaf1 img{
            transform: translateY(50%);
            transition: transform 3s;
          }
          &.leaf2 img{
            transform: translateY(50%);
            transition: transform 2s;
          }
          &.leaf3 img{
            transform: translateY(50%);
            transition: transform 1s;
          }
        }
      }
      &.trans{
        .leaf{
          &.leaf1 img{
            transition: transform 1s;
            transform: translateX(-500%) translateY(50%);
          }
          &.leaf2 img{
            transition: transform 1s;
            transform: translateX(-500%) translateY(50%);
          }
          &.leaf3 img{
            transition: transform 1s;
            transform: translateX(-500%) translateY(50%);
          }
        }
      }
      .leaf{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity 1s linear,transform .2s linear;
        img{
          display: block;
          height: auto;
          // transition: transform 1s;
        }
        &.leaf1{
          top: 10%;
          left: 35%;
          img{
            transform: translateX(-500%) translateY(-500%);
            @include res(width,1.12rem, (md:70px,xs:60px));
          }
        }
        &.leaf2{
          top: 36%;
          left: 44%;
          img{
            transform: translateX(400%) translateY(-300%);
            @include res(width,1.87rem, (md:90px,xs:80px));
          }
        }
        &.leaf3{
          // top: 93%;
          top: 88%;
          left: 19%;
          img{
            transform: translateX(-300%) translateY(-500%);
            @include res(width,2.21rem,(md:100px,xs:90px));
          }
        }
      }
    }
  }
  .section2{
    position: relative;
    &.active{
      .default-show{
        .left{
          .steps{
            ul{
              li{
                opacity: 1;
                &:nth-child(1){
                  transition-delay: .2s;
                }
                &:nth-child(2){
                  transition-delay: .5s;
                  transform: translateX(0) translateY(-50%);
                }
                &:nth-child(3){
                  transition-delay: .8s;
                  transform: translateX(0) translateY(-110%);
                }
                &:nth-child(4){
                  transition-delay: 1.1s;
                  transform: translateX(0) translateY(-150%);
                }
              }
            }
          }
        }
      }
    }
    &.show-detail{
      .default-show,.detail-cont{
        transform: translateX(-100%);
      }
    }
    .more-btn{
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 2;
      cursor: pointer;
      transform: translate3d(50%,-50%,0);
      opacity: 1;
      visibility: visible;
      transition: all 1s;
      &.hide{
        opacity: 0;
        visibility: hidden;
      }
      &::after,&::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 50%;
      }
      &::before{
        animation: scale 3.2s infinite ease-out;
        animation-delay: .8s;
      }
      &::after{
        animation: scale 3.2s infinite ease-out;
        animation-delay: 1.6s;
      }
      .icon{
        border-radius: 50%;
        background-color: #257f62;
        cursor: pointer;
        position: relative;
        @include res(width,1.18rem,(md:118px));
        @include res(height,1.18rem,(md:118px));
        .iconfont{
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          @include res(font-size,.46rem,(md:46px));
          &::before{
            @include res(margin-left,.08rem, (md:5px));
          }
          // &::after{
          //   content: '';
          //   display: block;
          //   width: 100%;
          //   height: 100%;
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   z-index: -1;
          //   border-radius: 50%;
          //   animation: scale 3s infinite ease-out;
          //   animation-delay: 1.8s;
          // }
        }
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          border-radius: 50%;
          animation: scale 3.2s infinite ease-out;
          animation-delay: 2.4s;
        }
        &::before{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          border-radius: 50%;
          animation: scale 3.2s infinite ease-out;
        }
      }
      span{
        position: absolute;
        top: 50%;
        right: 110%;
        white-space: nowrap;
        color: #257f62;
        font-family: 'Seed-bold';
        z-index: 1;
        transform: translateY(-50%);
        @include res(font-size, .2rem, (md:18px,xs:16px));
      }
    }
    .default-show{
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      transition: transform 1s $anime-bezier;
      &::after{
        content: '';
        display: block;
        width: 50%;
        height: 100%;
        // background: url(./images/section2_bg.png) no-repeat center center;
        background: url(./images/section2_bg_op.png) no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        @include res(border-top-right-radius,.3rem);
        @include res(border-bottom-right-radius,2rem);
      }
      .left,.right{
        flex-shrink: 0;
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        @include res(width,50%);
      }
      .left{
        color: #085039;
        @include res(padding-left,1.45rem, (md:40px,xs:20px));
        @include res(padding-right,1rem,(md:40px,xs:20px));
        h1{
          font-family: 'Seed-bold';
          background-image: linear-gradient(to right, #25b170, #49acb6,#49acb6);
          -webkit-background-clip: text;
          color: transparent;
          line-height: 1;
          @include res(font-size,.9rem);
        }
        h2{
          font-family: 'Seed-bold';
          line-height: 1.4;
          @include res(font-size,.32rem);
          @include res(margin-top,.5rem);
          @include res(margin-bottom,.3rem);
        }
        h3{
          font-family: 'Seed-bold';
          @include res(font-size,.22rem);
          @include res(margin-bottom,.3rem);
        }
        .desc{
          @include res(font-size,.2rem);
        }
        .steps{
          @include res(margin-top,2.3rem);
          ul{
            display: flex;
            align-items: flex-end;
            li{
              box-sizing: border-box;
              border-radius: .13rem;
              color: #085039;
              line-height: 1;
              flex-shrink: 0;
              opacity: 0;
              transition: all 0.7s;
              transition-delay: 0;
              @include res(width,38%);
              span{
                display: block;
                font-family: 'Seed-bold';
                margin-bottom: .05rem;
                white-space: nowrap;
              }
              &:not(:first-child){
                margin-left: -13%;
              }
              &:nth-child(1){
                width: 30%;
                background-color: rgba(255,255,255,.4);
                @include res(padding-left,.15rem);
                @include res(padding-right,.15rem);
                @include res(padding-top,.3rem);
                @include res(padding-bottom,.3rem);
                @include res(font-size, .15rem);
              }
              &:nth-child(2){
                width: 32%;
                background-color: rgba(161,227,199,.8);
                transform: translateX(-10%) translateY(-20%);
                @include res(padding-left,.2rem);
                @include res(padding-right,.2rem);
                @include res(padding-top,.3rem);
                @include res(padding-bottom,.2rem);
                @include res(font-size, .17rem);
              }
              &:nth-child(3){
                width: 40%;
                background-color: rgba(108,221,172,.8);
                transform: translateX(-10%) translateY(-70%);
                @include res(padding-left,.25rem);
                @include res(padding-right,.25rem);
                @include res(padding-top,.35rem);
                @include res(padding-bottom,.23rem);
                @include res(font-size, .21rem);
              }
              &:nth-child(4){
                width: 44%;
                background-color: rgba(37,177,113,.9);
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateX(-10%) translateY(-120%);
                @include res(padding-left,.3rem);
                @include res(padding-right,.3rem);
                @include res(padding-top,.4rem);
                @include res(padding-bottom,.4rem);
                span{
                  color: #fff;
                  @include res(font-size,.35rem);
                }
                &:hover{
                  .icon{
                    .wen{
                      transform: rotate(90deg);
                      opacity: 0;
                    }
                    .jian{
                      transform: translateX(20px);
                      opacity: 1;
                    }
                  }
                }
                .icon{
                  position: relative;
                  transform: translateY(.4rem);
                  @include res(margin-left, .7rem);
                  .wen{
                    color: #fff;
                    font-family: 'Seed-bold';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform-origin: bottom center;
                    transition: all $anime-duration;
                    @include res(font-size,.84rem);
                    b{
                      display: block;
                      animation: swing 2s infinite;
                    }
                  }
                  .jian{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform-origin: bottom center;
                    transform: translateX(-.2rem);
                    opacity: 0;
                    transition: all $anime-duration;
                    @include res(margin-bottom, .1rem);
                    .iconfont{
                      color: #fff;
                      @include res(font-size,.6rem);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .right{
        @include res(padding-left,.65rem);
        @include res(padding-right,2.5rem);
        ul{
          li{
            color: #085039;
            font-family: 'Seed-bold';
            line-height: 1.2;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            @include res(padding-top,.5rem);
            @include res(padding-bottom,.5rem);
            @include res(font-size, .34rem);
            &:not(:last-child){
              border-bottom: .01rem solid #26b171;
            }
            span{
              font-family: 'Seed-bold';
              @include res(font-size,.6rem);
              @include res(margin-right,.35rem);
            }
            &:nth-child(1) span{
              color: #25b171;
            }
            &:nth-child(2) span{
              color: #2c9d69;
            }
            &:nth-child(3) span{
              color: #21a489;
            }
            &:nth-child(4) span{
              color: #228f99;
            }
          }
        }
      }
    }
    .detail-cont{
      position: absolute;
      left: 100%;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      transition: transform 1s $anime-bezier;
      .cont{
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        overflow-x: hidden;
        pointer-events: all !important;
        &::-webkit-scrollbar {
          width: 0px;    
        }
      }
      .xscroll-tab{
        position: absolute;
        z-index: 2;
        @include res(width,60%);
        @include res(bottom,75%);
        @include res(left,6%);
        .item{
          position: absolute;
          bottom: 0;
          left: 0;
          font-family: 'Seed-bold';
          color: $color-main;
          line-height: 1.16;
          display: flex;
          align-items: flex-start;
          opacity: 0;
          visibility: hidden;
          transition: all $anime-duration;
          @include res(font-size,.56rem);
          span{
            font-family: 'Seed-bold';
            @include res(margin-right,.4rem);
            @include res(font-size, 1rem);
          }
          &.active{
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .xscroll-cont{
        position: absolute;
        height: 100vh;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 0;
        .swiper{
          height: 100%;
        }
        .item{
          height: 60vh;
          margin-top: 30vh;
          display: flex;
          align-items: center;
          justify-content: center;
          .t{
            color: $color-main;
            flex-shrink: 0;
            line-height: 1.5;
            @include res(font-size,.22rem);
            @include res(margin-right,.8rem);
            @include res(padding-left,.2rem);
            @include res(width,40%);
            h4{
              font-family: 'Seed-bold';
              @include res(font-size,.36rem);
              @include res(margin-bottom,.15rem);
              &.hasdian{
                position: relative;
                &::after{
                  content: '';
                  display: block;
                  border-radius: 50%;
                  background-color: $color-main;
                  position: absolute;
                  left: -3%;
                  top: calc(50% - .04rem);
                  @include res(width,.08rem);
                  @include res(height,.08rem);
                }
              }
            }
            p{
              &:not(:last-child){
                @include res(margin-bottom,.3rem);
              }
              &.hasdian{
                position: relative;
                &::after{
                  content: '';
                  display: block;
                  border-radius: 50%;
                  background-color: $color-main;
                  position: absolute;
                  left: -3%;
                  @include res(top,.12rem);
                  @include res(width,.08rem);
                  @include res(height,.08rem);
                }
              }
            }
          }
          .img{
            @include res(width,40%);
            img{
              display: block;
              width: 100%;
              height: auto;
            }
            video{
              display: block;
              width: 100%;
              height: auto;
            }
            &.hastext{
              position: relative;
              .t{
                position: absolute;
                top:0;
                width: 100%;
                text-align: right;
                color: #fff;
                font-family: 'Seed-bold';
                background:linear-gradient(top,rgba(8,80,57,.8),rgba(8,80,57,0));
                @include res(padding-top,.2rem);
                @include res(padding-bottom,.2rem);
                @include res(padding-left,.6rem);
                @include res(padding-right,.6rem);
                @include res(font-size, .2rem);
              }
            }
          }
        }
      }
      .swiper-contral{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 5%;
        padding: 0 8%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        .swiper-pagination{
          position: static;
          background-color: #fff;
          @include res(height,.03rem);
          @include res(width,4.25rem);
        }
        .swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
          background-color: $color-main;
        }
        .swiper-btn{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .swiper-button-prev,.swiper-button-next{
            position: static;
            color: $color-main;
            width: .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s;
            &:hover{
              color: $color-main-light;
            }
            &::after{
              font-size: .4rem;
            }
          }
        }
      }
      .progress{
        position: absolute;
        z-index: 2;
        background-color: #fff;
        @include res(bottom,8%);
        @include res(left,6%);
        @include res(height,.03rem);
        @include res(width,4.25rem);
        div{
          position: absolute;
          height: 100%;
          z-index: 3;
          background-color: $color-main;
        }
      }
      .btn{
        padding: .05rem;
        white-space: nowrap;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        border-radius: .5rem;
        box-shadow: 0px 0px .1rem rgba(8,80,57,.2);
        transition: all $anime-duration;
        cursor: pointer;
        &:hover{
          box-shadow: 0px 0px .15rem rgba(8,80,57,.4);
        }
        .icon{
          border-radius: 50%;
          position: relative;
          overflow: hidden;
          background-color: #26b171;
          transition: all $anime-duration;
          @include res(margin-right,.15rem);
          @include res(width,.32rem);
          @include res(height,.32rem);
          .go{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            background-color: #26b171;
            transform-origin: left center;
            transition: all .5s cubic-bezier(.19,1,.22,1);
            border-radius: 50%;
            .iconfont{
              color: #fff;
              @include res(font-size, .2rem);
            }
          }
          .back{
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            background-color: $color-main;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            transform-origin: right center;
            transition: all .5s cubic-bezier(.19,1,.22,1);
            transform: scale(0);
            .iconfont{
              color: #fff;
              @include res(font-size, .2rem);
            }
          }
        }
        .text{
          position: relative;
          font-family: 'Seed-bold';
          overflow: hidden;
          line-height: 1;
          @include res(margin-right, .2rem);
          .go{
            color: #26b171;
            transition: all $anime-duration;
            position: absolute;
            top: 0;
            left: 0;
          }
          .back{
            color: $color-main;
            transform: translateY(100%);
            transition: all $anime-duration;
          }
        }
        &.back-btn{
          .icon{
            background-color: $color-main;
            .go{
              transform: scale(0);
            }
            .back{
              transform: scale(1);
            }
          }
          .text{
            .go{
              transform: translateY(-100%);
            }
            .back{
              transform: translateY(0);
            }
          }
        }
      }
    }
    .leaf-cont{
      &.show{
        .leaf{
          opacity: 1;
          &.leaf4 img{
            transition: transform 4s;
            transform: translateX(0) translateY(0);
          }
          &.leaf5 img{
            transition: transform 5s;
            transform: translateX(0) translateY(0);
          }
        }
      }
      &.trans{
        .leaf{
          &.leaf4 img{
            transition: transform 1s;
            transform: translateX(-500%);
          }
          &.leaf5 img{
            transition: transform 1s;
            transform: translateX(-500%);
          }
        }
      }
      &.next{
        .leaf{
          &.leaf4 img{
            transform: translateY(50%);
            transition: transform 3s;
          }
          &.leaf5 img{
            transform: translateY(50%);
            transition: transform 2s;
          }
        }
      }
      .leaf{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity 1s linear,transform .2s linear;
        img{
          display: block;
          height: auto;
          transition: transform 1s;
        }
        &.leaf4{
          top: 8%;
          left: 86%;
          z-index: -1;
          img{
            transform: translateX(-100%) translateY(-100%);
            @include res(width,1.96rem);
          }
        }
        &.leaf5{
          top: 70%;
          left: 75%;
          z-index: -1;
          img{
            transform: translateX(300%) translateY(-500%);
            @include res(width,2.49rem);
          }
        }
      }
    }
  }
  .section3{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    @include res(padding-left,1.45rem);
    @include res(padding-right,1.45rem);
    .left{
      flex-shrink: 0;
      position: relative;
      z-index: 2;
      @include res(margin-right,1.8rem);
      h1{
        font-family: 'Seed-bold';
        background-image: linear-gradient(to right, #25b170, #49acb6,#49acb6);
        -webkit-background-clip: text;
        color: transparent;
        line-height: 1;
        @include res(font-size,.9rem);
        @include res(margin-bottom, .4rem);
      }
      .link{
        .common-link{
          @include res(width,2.4rem);
        }
      }
    }
    .right{
      flex-grow: 1;
      position: relative;
      z-index: 2;
      .pro1{
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
        @include res(margin-bottom,.4rem);
        >div{
          width: 100%;
          display: flex;
          align-items: stretch;
          justify-content: center;
        }
        li{
          padding: .05rem;
          border-radius: .5rem;
          background:linear-gradient(top,#c3f4ce,#a4ddce);
          transition: all $anime-duration;
          @include res(width,3rem);
          @include res(margin-bottom,.15rem);

          &:not(:last-child){
            @include res(margin-right,.15rem);
          }
          &:hover{
            box-shadow: .2rem -.2rem .4rem #88c9b8 inset;
            a{
              .icon{
                background-color: #ffffff;
              }
            }
          }
          a{
            display: flex;
            align-items: center;
            color: $color-main;
            font-family: 'Seed-bold';
            white-space: nowrap;
            @include res(font-size,.23rem);
            .icon{
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: .5rem;
              background-color: #e2f7eb;
              flex-shrink: 0;
              transition: all $anime-duration;
              // @include res(margin-right,.2rem);
              // @include res(margin-right,.1rem);
              img{
                display: block;
                height: auto;
                @include res(width, .5rem);
              }
            }
            p{
              width: calc(100%-.5rem);
              text-align: center;
            }
          }
        }
      }
      .pro2{
        border-top: .01rem solid #21653b;
        li{
          border-bottom: .01rem solid #21653b;
          &:hover{
            a{
              .title span::after{
                transform: scaleX(1);
              }
              .desc{
                color: $color-main-light;
              }
            }
          }
          a{
            align-items: flex-start;
            justify-content: flex-start;
            color: $color-main;
            @include res(display,flex);
            @include res(padding-top,.15rem);
            @include res(padding-bottom,.1rem);
            .title{
              flex-shrink: 0;
              font-family: 'Seed-bold';
              @include res(font-size,.26rem);
              @include res(width,34%);
              span{
                position: relative;
                &::after{
                  content: '';
                  display: block;
                  width: 100%;
                  background-color: $color-main;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  transition: all $anime-duration;
                  transform-origin: left;
                  transform: scaleX(0);
                  @include res(height,.02rem);
                }
              }
            }
            .desc{
              flex-shrink: 0;
              transition: all $anime-duration;
              @include res(font-size, .17rem);
              @include res(width,66%);
              @extend %clearfix;
              span{
                display: block;
                width: 50%;
                float: left;
              }
            }
          }
        }
      }
    }
    .fixed-img{
      .circle-img{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: transform .2s linear;
        img{
          border-radius: 50%;
          display: block;
          width: 100%;
          height: auto;
        }
        &.img1{
          top: 5%;
          left: 16%;
          @include res(width,3.3rem);
        }
        &.img2{
          top: 45%;
          left: 93%;
          @include res(width,2.68rem);
        }
        &.img3{
          top: 84%;
          left: 42%;
          @include res(width,1.98rem);
        }
      }
    }
    .leaf-cont{
      &.show{
        .leaf{
          opacity: 1;
          &.leaf6 img{
            transition: transform 3s;
            transform: translateX(0) translateY(0);
          }
          &.leaf7 img{
            transition: transform 5s;
            transform: translateX(0) translateY(0);
          }
          &.leaf8 img{
            transition: transform 5s;
            transform: translateX(0) translateY(0);
          }
        }
      }
      &.next{
        .leaf{
          &.leaf6 img{
            transform: translateY(50%);
            transition: transform 3s;
          }
          &.leaf7 img{
            transform: translateY(50%);
            transition: transform 2s;
          }
          &.leaf8 img{
            transform: translateY(50%);
            transition: transform 1s;
          }
        }
      }
      .leaf{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity 1s linear,transform .2s linear;
        >div{
          transition: transform 1s $anime-bezier;
        }
        img{
          display: block;
          height: auto;
          transform-origin: center;
          transition: all .6s;
        }
        &.leaf6{
          top: 13%;
          left: 3%;
          img{
            transform: translateX(-200%) translateY(-300%);
            @include res(width,1.41rem);
          }
        }
        &.leaf7{
          top: 33%;
          left: 87%;
          z-index: -1;
          img{
            transform: translateX(100%) translateY(-300%);
            @include res(width,1.87rem);
          }
        }
        &.leaf8{
          top: 77%;
          left: 19%;
          img{
            transform: translateX(-100%) translateY(-500%);
            @include res(width,1.12rem);
          }
        }
      }
    }
  }
}

.mob-index{
  @include res(display,none,(md:block));
  .section1{
    .swiper{
      overflow: hidden;
      position: relative;
      @include res(height,600px,(sm:500px,xs:300px));
      .swiper-slide{
        background-position: center;
        background-size: cover;
      }
      .swiper-pagination{
        text-align: right;
        @include res(padding-right,70px, 20 / 70);
        @include res(bottom,20px);
        .swiper-pagination-bullet{
          margin: 0 6px;
          width: 6px;
          height: 6px;
          position: relative;
          background: #25b171;
          opacity: 1;
          &::after{
            content: '';
            display: block;
            border-radius: 50%;
            border: 1px solid #7bd3ab;
            position: absolute;
            top: 50%;
            left:50%;
            opacity: 0;
            transform: translate3d(-50%,-50%,0);
            transition: all $anime-duration;
            @include res(width,16px);
            @include res(height,16px);
          }
          &.swiper-pagination-bullet-active{
            &::after{
              opacity: 1;
            }
          }
        }
      }
    }
    .left{
      flex-shrink: 0;
      box-sizing: border-box;
      @include res(padding-left,80px, 20 / 80);
      @include res(padding-right,70px, 20 / 70);
      @include res(margin-bottom, 160px, 50 / 160);
      @include res(margin-top, 50px, 20 / 50);
      h1{
        font-family: 'Seed-bold';
        background-image: linear-gradient(to right, #25b170, #49acb6,#49acb6);
        -webkit-background-clip: text;
        color: transparent;
        @include res(text-align,null,(xs:center));
        @include res(font-size,90px,40 / 90);
      }
      .desc{
        color: $color-main;
        @include res(font-size, 20px, 18 / 20);
      }
      .link{
        @include res(margin-top,40px, 20 / 40);
        .common-link{
          @include res(margin,null,(xs:0 auto));
        }
      }
    }
  }
  .section2{
    @include res(margin-bottom, 150px, 50 / 150);
    .left{
      color: #085039;
      @include res(padding-left,80px, 20 / 80);
      @include res(padding-right,70px, 20 / 70);
      @include res(margin-bottom, 80px, 50 / 80);
      h1{
        font-family: 'Seed-bold';
        background-image: linear-gradient(to right, #25b170, #49acb6,#49acb6);
        -webkit-background-clip: text;
        color: transparent;
        line-height: 1;
        @include res(text-align,null,(xs:center));
        @include res(font-size,90px,40 / 90);
        @include res(margin-bottom, 50px, 20 / 50);
      }
      h2{
        font-family: 'Seed-bold';
        line-height: 1.4;
        @include res(margin-top,.1rem);
        @include res(margin-bottom,.1rem);
        @include res(font-size,50px, 20 / 50);
      }
      h3{
        font-family: 'Seed-bold';
        @include res(font-size,22px, 18 / 22);
        @include res(margin-bottom,30px, 15 / 30);
      }
      .desc{
        @include res(font-size,20px, 16 / 20);
      }
      .steps{
        @include res(margin-top,250px, 120 / 250);
        ul{
          display: flex;
          align-items: flex-end;
          li{
            box-sizing: border-box;
            border-radius: 13px;
            color: #085039;
            line-height: 1;
            flex-shrink: 0;
            opacity: 0;
            transition: all $anime-duration;
            transition-delay: 0;
            @include res(width,38%);
            span{
              display: block;
              font-family: 'Seed-bold';
              margin-bottom: 5px;
              white-space: nowrap;
            }
            &:not(:first-child){
              margin-left: -13%;
            }
            &:nth-child(1){
              width: 30%;
              background-color: rgba(185,223,218,.6);
              @include res(padding-left,15px, 10 / 15);
              @include res(padding-right,15px, 10 / 15);
              @include res(padding-top,30px, 15 / 30);
              @include res(padding-bottom,30px, 15 / 30);
              @include res(font-size, 15px, 12 / 15);
            }
            &:nth-child(2){
              width: 32%;
              background-color: rgba(161,227,199,.8);
              @include res(padding-left,20px, 10 / 20);
              @include res(padding-right,20px, 10 / 20);
              @include res(padding-top,30px, 15 / 30);
              @include res(padding-bottom,20px, 15 / 20);
              @include res(font-size, 17px, 12 / 17);
            }
            &:nth-child(3){
              width: 40%;
              background-color: rgba(108,221,172,.8);
              @include res(padding-left,25px, 15 / 25);
              @include res(padding-right,25px, 15 / 25);
              @include res(padding-top,35px, 15 / 35);
              @include res(padding-bottom,30px, 15 / 30);
              @include res(font-size, 21px, 13 / 21);
            }
            &:nth-child(4){
              width: 40%;
              background-color: rgba(37,177,113,.9);
              display: flex;
              align-items: center;
              justify-content: center;
              @include res(padding-left,30px, 15 / 30);
              @include res(padding-right,30px, 15 / 30);
              @include res(padding-top,40px, 20 / 40);
              @include res(padding-bottom,40px, 20 / 40);
              span{
                color: #fff;
                @include res(font-size,35px, 20 / 35);
              }
              &:hover{
                .icon{
                  .wen{
                    transform: rotate(90deg);
                    opacity: 0;
                  }
                  .jian{
                    transform: translateX(20px);
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
        &.active{
          ul{
            li{
              opacity: 1;
              &:nth-child(1){
                transition-delay: .3s;
              }
              &:nth-child(2){
                transition-delay: .7s;
                transform: translateY(-50%);
              }
              &:nth-child(3){
                transition-delay: 1.1s;
                transform: translateY(-120%);
              }
              &:nth-child(4){
                transition-delay: 1.5s;
                @include res(transform,translateY(-180%),(xs:translateY(-240%)))
              }
            }
          }
        } 
      }
    }
    .right{
      @include res(padding-left,80px, 20 / 80);
      @include res(padding-right,70px, 20 / 70);
      ul{
        li{
          @include res(margin-bottom, 50px, 40 / 50);
          .title{
            font-family: 'Seed-bold';
            color: $color-main;
            line-height: 1.16;
            display: flex;
            align-items: flex-start;
            @include res(font-size,56px, 20 / 56);
            @include res(margin-bottom, 50px, 20 / 50);
            span{
              font-family: 'Seed-bold';
              @include res(margin-right,40px, 20 / 40);
              @include res(font-size, 100px, 40 / 100);
            }
          }
          .desc{
            .t{
              color: $color-main;
              flex-shrink: 0;
              line-height: 1.5;
              @include res(font-size,22px, 16 / 22);
              h4{
                font-family: 'Seed-bold';
                @include res(font-size,36px, 20 / 36);
                @include res(margin-bottom,15px, 10 / 15);
                &.hasdian{
                  position: relative;
                  @include res(padding-left, 1px, 5 / 1);
                  &::after{
                    content: '';
                    display: block;
                    border-radius: 50%;
                    background-color: $color-main;
                    position: absolute;
                    left: -2%;
                    top: calc(50% - 4px);
                    @include res(width,8px, 5 / 8);
                    @include res(height,8px, 5 / 8);
                  }
                }
              }
              p{
                &:not(:last-child){
                  @include res(margin-bottom,30px, 15 / 30);
                }
                &.hasdian{
                  position: relative;
                  @include res(padding-left, 1px, 5 / 1);
                  &::after{
                    content: '';
                    display: block;
                    border-radius: 50%;
                    background-color: $color-main;
                    position: absolute;
                    left: -3%;
                    @include res(top,12px, 10 / 12);
                    @include res(width,8px, 5 / 8);
                    @include res(height,8px, 5 / 8);
                  }
                }
              }
            }
            .img{
              @include res(margin-top, 50px, 20 / 50);
              img{
                display: block;
                width: 100%;
                height: auto;
              }
              video{
                display: block;
                width: 100%;
                height: auto;
              }
              &.hastext{
                position: relative;
                .t{
                  position: absolute;
                  top:0;
                  width: 100%;
                  text-align: right;
                  color: #fff;
                  font-family: 'Seed-bold';
                  background:linear-gradient(top,rgba(8,80,57,.8),rgba(8,80,57,0));
                  @include res(padding-top,20px, 10 / 20);
                  @include res(padding-bottom,20px, 10 / 20);
                  @include res(padding-left,60px, 20 / 60);
                  @include res(padding-right,60px, 20 / 60);
                  @include res(font-size, 20px, 16 / 20);
                }
              }
            }
          }
        }
      }
    }
  }
  .section3{
    @include res(padding-left,80px, 20 / 80);
    @include res(padding-right,70px, 20 / 70);
    @include res(margin-bottom, 100px, 50 / 100);
    .left{
      align-items: center;
      justify-content: space-between;
      @include res(display,flex,(xs:block));
      @include res(margin-bottom, 40px, 20 / 40);
      h1{
        font-family: 'Seed-bold';
        background-image: linear-gradient(to right, #25b170, #49acb6,#49acb6);
        -webkit-background-clip: text;
        color: transparent;
        line-height: 1;
        @include res(text-align,null,(xs:center));
        @include res(font-size,90px,40 / 90);
        @include res(margin-bottom,0,(xs:20px));
      }
      .link{
        flex-shrink: 0;
        @include res(display,block,(xs:none));
        .common-link{
          @include res(margin,null,(xs:0 auto));
          @include res(width,205px);
        }
      }
    }
    .right{
      .pro1{
        @extend %clearfix;
        @include res(margin-bottom,40px, 20 / 40);
        @include res(width,null,(xs:calc(100% + 20px)));
        li{
          float: left;
          padding: 5px;
          border-radius: 50px;
          background:linear-gradient(top,#c3f4ce,#a4ddce);
          transition: all $anime-duration;
          @include res(width,280px,(lg:240px,mmd:220px,xs:calc(50% - 10px)));
          @include res(margin-right,15px, 5 / 15);
          @include res(margin-bottom,15px, 5 / 15);
          &:hover{
            box-shadow: 20px -20px 40px #88c9b8 inset;
            a{
              .icon{
                background-color: #ffffff;
              }
            }
          }
          a{
            display: flex;
            align-items: center;
            color: $color-main;
            font-family: 'Seed-bold';
            white-space: nowrap;
            @include res(font-size,16px, (sm:16px,xs:12px));
            .icon{
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50px;
              background-color: #e2f7eb;
              flex-shrink: 0;
              transition: all $anime-duration;
              @include res(margin-right,20px, 10 / 20);
              @include res(width,62px, 40 / 62);
              @include res(height,62px, 40 / 62);
              @media only screen and (width:1440px) {
                margin-right: 10px;
              }
              img{
                display: block;
                height: auto;
                @include res(width, 50px, 30 / 50);
              }
            }
          }
        }
      }
      .pro2{
        border-top: 1px solid #21653b;
        li{
          border-bottom: 1px solid #21653b;
          a{
            align-items: flex-start;
            justify-content: flex-start;
            color: $color-main;
            @include res(display,flex,(xs:block));
            @include res(padding-top,15px, 10 / 15);
            @include res(padding-bottom,15px, 10 / 15);
            .title{
              flex-shrink: 0;
              font-family: 'Seed-bold';
              @include res(font-size,26px, 20 / 26);
              @include res(width,34%,(xs:100%));
              @include res(margin-bottom,0,(xs:5px));
            }
            .desc{
              flex-shrink: 0;
              @include res(font-size, 17px, 14 / 17);
              @include res(width,66%,(xs:100%));
              @extend %clearfix;
              span{
                display: block;
                width: 50%;
                float: left;
                line-height: 1.2;
                @include res(margin-bottom,20px, 10 / 20);
              }
            }
          }
        }
      }
    }
    .mob-link{
      @include res(margin-top, 50px, 30 / 50);
      @include res(display,none,(xs:block));
      .common-link{
        @include res(margin,null,(xs:0 auto));
        @include res(width,205px);
      }
    }
  }
}


@keyframes scale{
  0%{ transform: scale(1);opacity:1;background-color:  rgba(100,222,174, 1);}
  100%{ transform: scale(4);opacity:0;background-color:  rgba(70,184,162,1)}
}
@keyframes swing {
  20% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

